const WHOLE_GALLERY = "gallery"
const SINGLE_PHOTO = "photo"
const SINGLE_VIDEO = "video"
const MULTIPLY_SCENES = "scenes"
const FAVORITE_PHOTOS = "favorite-photos"
const DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE = "original"
const YANDEX_CLOUD_OAUTH_DENIED = "yandex-cloud-oauth-denied"
const YANDEX_CLOUD_TOKEN = "yandex-cloud-token"
const DOWNLOAD_LOADING = "loading"
const DOWNLOAD_SUCCESS = "success"
const DOWNLOAD_ERROR = "error"
const DOWNLOAD_CONSUMER_DEVICE = "onDevice"
const DOWNLOAD_CONSUMER_YANDEX = "inYandexCloud"
const DOWNLOAD_PROVIDER_YANDEX = "yandex_drive"
const DOWNLOAD_PROVIDER_GOOGLE = "google_drive"
const FAVORITE_LIST = "list"
const DOWNLOAD_CONSUMER_GOOGLE = "inGoogleCloud"
const GOOGLE_CREDENTIALS_SCOPE = "https://www.googleapis.com/auth/drive.file"
const AWS_BUCKET = process.env.NEXT_PUBLIC_AWS_BUCKET
const AWS_ENDPOINT = "https://storage.googleapis.com" // "https://storage.yandexcloud.net"
const ACCEPT_FILES = {
  image: ["jpeg", "jpg", "gif"],
  video: ["mp4", "webm", "mov"],
}

export {
  ACCEPT_FILES,
  AWS_BUCKET,
  AWS_ENDPOINT,
  DEFAULT_IMAGE_RESOLUTION_DOWNLOAD_TYPE,
  DOWNLOAD_CONSUMER_DEVICE,
  DOWNLOAD_CONSUMER_GOOGLE,
  DOWNLOAD_CONSUMER_YANDEX,
  DOWNLOAD_ERROR,
  DOWNLOAD_LOADING,
  DOWNLOAD_PROVIDER_GOOGLE,
  DOWNLOAD_PROVIDER_YANDEX,
  DOWNLOAD_SUCCESS,
  FAVORITE_LIST,
  FAVORITE_PHOTOS,
  GOOGLE_CREDENTIALS_SCOPE,
  MULTIPLY_SCENES,
  SINGLE_PHOTO,
  SINGLE_VIDEO,
  WHOLE_GALLERY,
  YANDEX_CLOUD_OAUTH_DENIED,
  YANDEX_CLOUD_TOKEN,
}
